import _ from 'lodash'
import CbsApi from '@/api/contentBlockService'
import { CONTENT_BLOCK_VLM, CONTENT_BLOCK_NMH } from '@/model/ContentBlock.js'
import ContentBlockFilter from '@/model/ContentBlockFilter'
import ContentBlockFilterService from '@/services/contentBlock/ContentBlockFilterService'
import Config from '@/config'
const CONTENT_BLOCKS_API = 'ContentBlocks'
const CONTENT_BLOCK_API = 'ContentBlock'
const CONTENT_BLOCK_CATEGORY_API_NAME = '/ContentBlockCategory'
const LIMIT = 600
const LOCALSTORAGE_FILTER = 'contentBlock/hpManagementFilter'

const state = {
  error: null,
  errorMessage: null,
  detail: Config.isVlm() ? CONTENT_BLOCK_VLM : CONTENT_BLOCK_NMH,
  contentBlockCategory: null,
  list: [],
  all: [],
  contentBlockWithManagedItems: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(ContentBlockFilter),
  hpManagementFilter: {
    site: 1,
    contentBlocks: [],
    categoryId: 1
  },
  limit: 100
}

const mutations = {
  storeContentBlockCategory (state, res) {
    state.contentBlockCategory = res
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  storeCBWithManagedItems (state, responseData) {
    state.contentBlockWithManagedItems = responseData.data
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setError (state, message) {
    state.error = message
  },
  setErrorMessage (state, errorMessage) {
    state.errorMessage = errorMessage
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(ContentBlockFilter)
  },
  setHpManagementFilter (state, hpManagementFilter) {
    state.hpManagementFilter = hpManagementFilter
    if (window.localStorage) {
      window.localStorage.setItem(LOCALSTORAGE_FILTER, JSON.stringify(hpManagementFilter))
    }
  }
}

const actions = {
  async fetchContentBlockCategory ({ commit }) {
    await CbsApi().get(CONTENT_BLOCK_CATEGORY_API_NAME)
      .then(res => {
        commit('storeContentBlockCategory', res.data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  async fetch (store, params) {
    store.commit('storeList', {
      data: [],
      totalCount: 0
    })
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * store.state.limit) - store.state.limit
    const url =
      `/${store.getters.cbsApi}?limit=${store.state.limit}&offset=${offset}` +
      ContentBlockFilterService.buildFilterQuery({ ...store.state.filter, ...params })
    await CbsApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CbsApi().get(`/${store.getters.cbsApi}/${id}`)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
    await store.dispatch('fetchContentBlockCategory')
  },
  async fetchBySites (store, sites) {
    const siteNames = sites.map(site => site.name)
    const sitesIds = sites.map(site => site.id)
    return await CbsApi().post(store.getters.cbsApi + '/hp-article-content-blocks-by-sites', { siteNames, sitesIds })
  },
  async create (store, record) {
    return await CbsApi().post(`/${store.getters.cbsApi}`, JSON.stringify(record))
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return response.data
        }
        store.commit('setError', 'Error')
        return undefined
      })
      .catch(error => {
        const status = error.response.status
        if (status >= 400 && status < 600) {
          store.commit('setError', error.response.status)
          store.commit('setErrorMessage', error.response.data.error || error.response.data.title || error.response.status)
          return
        }
        store.commit('setError', error.response.status || 'Unknown error')
      })
  },
  async update (store, record) {
    return await CbsApi().put(`/${store.getters.cbsApi}/${record.id}`, JSON.stringify(record))
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return
        }
        store.commit('setError', 'Error')
      })
      .catch(error => {
        const status = error.response.status
        if (status >= 400 && status < 600) {
          store.commit('setError', error.response.status)
          return
        }
        store.commit('setError', error.response.data?.error || 'Unknown error')
      })
  },
  async deleteRecord (store, record) {
    return await CbsApi().delete(`/${store.getters.cbsApi}/${record.id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          store.commit('setError', null)
          return
        }
        store.commit('setError', 'Error')
      })
      .catch(error => {
        const status = error.response.status
        if (status >= 400 && status < 600) {
          store.commit('setError', error.response.status)
          return
        }
        store.commit('setError', error.response.data?.error || 'Unknown error')
      })
  },
  async fetchAll ({ commit, getters }) {
    commit('storeAll', {
      data: []
    })
    return await CbsApi().get(`/${getters.cbsApi}?limit=${LIMIT}`)
      .then(res => {
        commit('storeAll', res.data)
        return res.data
      })
      .catch(error => console.error(error))
  },
  fetchForHpManagement (store) {
    store.commit('storeList', {
      data: [],
      totalCount: 0
    })
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * store.state.limit) - store.state.limit
    const url = `/${store.getters.cbsApi}` +
      '?limit=' + store.state.limit +
      '&offset=' + offset + ContentBlockFilterService.buildHpManagementFilterQuery(store.state.hpManagementFilter)
    CbsApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
        return res.data
      })
      .catch(error => console.log(error))
  },
  fetchWithManagedItems ({ state, commit, getters }) {
    commit('TOGGLE_LOADING', null, { root: true })
    const offset = (state.page * state.limit) - state.limit
    const url = `/${getters.cbsApi}` + '/get-with-managed-items' +
      '?limit=' + state.limit +
      '&offset=' + offset + ContentBlockFilterService.buildQueryForManagedItems(state.hpManagementFilter)
    CbsApi().get(url)
      .then(res => {
        commit('storeCBWithManagedItems', res.data)
        commit('TOGGLE_LOADING', null, { root: true })
        return res.data
      })
      .catch(error => console.error(error))
  }
}

const getters = {
  contentBlockSite (state) {
    return state.hpManagementFilter.site
  },
  contentBlockCategoryId (state) {
    return state.hpManagementFilter.categoryId
  },
  getContentBlockCategoryNameById (state) {
    return state.contentBlockCategory.find(category => category.id === state.hpManagementFilter.categoryId).name
  },
  cbsApi (state, getters, rootState, rootGetters) {
    return rootGetters.vlm ? CONTENT_BLOCK_API : CONTENT_BLOCKS_API
  },
  contentBlockCategory (state) {
    return state.contentBlockCategory
  },
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  contentBlockWithManagedItems (state) {
    return state.contentBlockWithManagedItems
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  limit (state) {
    return state.limit
  },
  error (state) {
    return state.error
  },
  errorMessage (state) {
    return state.errorMessage
  },
  filter (state) {
    return state.filter
  },
  hpManagementFilter (state, getters, rootState, rootGetters) {
    const currentUser = rootGetters['user/currentUser']
    if (currentUser.sites.includes(parseInt(state.hpManagementFilter.site)) === false) {
      state.hpManagementFilter.site = currentUser.sites[0]
      state.hpManagementFilter.contentBlocks = []
      state.hpManagementFilter.categoryId = 1
    }
    return state.hpManagementFilter
  },
  getContentBlockById (state) {
    return id => state.all.find(contentBlock => {
      if (id === contentBlock.id) {
        return contentBlock
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
