import CbsApi from '@/api/contentBlockService'
import { managedItemModel } from '@/model/ManagedItemModel'
import ErrorHandlingService from '@/services/ErrorHandlingService'
import { dataHubHpctrApi } from '@/api/datahub'
import ContentBlockItemModel from '@/model/ContentBlockItem.js'
import Config from '@/config'

const MANAGED_ITEMS_API = '/ManagedItems'
const MANAGED_ITEM_API = '/ManagedItem'

const state = {
  error: null,
  detail: Config.isVlm() ? managedItemModel : ContentBlockItemModel,
  list: [],
  all: [],
  totalCount: 0,
  page: 1,
  filterQuery: '',
  filter: {
    id: '',
    title: '',
    identifier: ''
  },
  mainCtrSelect: {
    value: 'total'
  },
  historicalCtrData: [],
  historicalCtrDataLoading: false,
  realtimeCtrData: [],
  realtimeCtrDataLoading: false
}

const mutations = {
  SET_MAIN_CTR_SELECT (state, data) {
    state.mainCtrSelect.value = data
  },
  ADD_HISTORICAL_CTR_DATA (state, data) {
    state.historicalCtrData.push(data)
  },
  SET_HISTORICAL_CTR_DATA (state, data) {
    state.historicalCtrData = state.historicalCtrData.map(item => {
      if (item.box === data.box) {
        return data
      } else {
        return item
      }
    })
  },
  SET_HISTORICAL_CTR_DATA_LOADING (state, data) {
    state.historicalCtrDataLoading = data
  },
  ADD_REALTIME_CTR_DATA (state, data) {
    state.realtimeCtrData.push(data)
  },
  SET_REALTIME_CTR_DATA (state, data) {
    state.realtimeCtrData = state.realtimeCtrData.map(item => {
      if (item.box === data.box) {
        return data
      } else {
        return item
      }
    })
  },
  SET_REALTIME_CTR_DATA_LOADING (state, data) {
    state.historicalCtrDataLoading = data
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setFilterQuery (state, filterQuery) {
    state.filterQuery = filterQuery
  },
  setTimeline (state, data) {
    state.timeline = data
  }
}

const actions = {
  async fetchOne ({ commit, getters, rootGetters }, id) {
    await CbsApi().get(getters.contentBlockItemApi + '/' + id)
      .then(response => {
        if (rootGetters.vlm) {
          const data = {
            ...managedItemModel,
            ...response.data,
            showUntil: response.data.showUntil,
            publishedSince: response.data.publishedSince
          }
          delete data.createdAt
          delete data.modifiedAt
          delete data.modifiedBy
          commit('storeDetail', data)
          commit('setError', null)
          return
        }
        commit('storeDetail', response.data)
        commit('setError', null)
      })
      .catch(error => console.error(error))
  },
  async create ({ commit, getters, rootGetters }, record) {
    const currentUserId = rootGetters['user/currentUser'].id
    if (currentUserId) {
      record.createdBy = currentUserId
    }
    return await CbsApi().post(getters.contentBlockItemApi, JSON.stringify(record))
      .then(response => {
        commit('storeDetail', response.data)
        commit('setError', null)
        return response.data
      })
      .catch(error => {
        commit('setError', error)
        ErrorHandlingService.handleError({ commit }, error)
      })
  },
  async update (store, record) {
    return await CbsApi().put(store.getters.contentBlockItemApi + '/' + record.id, JSON.stringify(record))
      .then(response => {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
        return response.data
      })
      .catch(error => {
        ErrorHandlingService.handleError(store, error)
      })
  },
  async deleteRecord (store, record) {
    return await CbsApi().delete(store.getters.contentBlockItemApi + '/' + record.id)
      .then(() => {
        store.commit('setError', null)
      })
      .catch(error => {
        ErrorHandlingService.handleError(store, error)
      })
  },
  // ********************************************************************************************************
  // https://dev.datahub.newsandmedia.sk/hpctr/docs
  // ********************************************************************************************************
  async getHpHistoricalCtrData (store, { brand, box }) {
    if (brand && box) {
      store.commit('SET_HISTORICAL_CTR_DATA_LOADING', true)
      let data = null
      try {
        const result = await dataHubHpctrApi().get(`/historical?brand=${brand}&box=${box}`)
        data = result.data
      } catch (error) {
        console.error(error)
      }
      if (data) {
        if (store.state.historicalCtrData.some(item => item.box === box)) {
          store.commit('SET_HISTORICAL_CTR_DATA', data)
        } else {
          store.commit('ADD_HISTORICAL_CTR_DATA', data)
        }
      }
      store.commit('SET_HISTORICAL_CTR_DATA_LOADING', false)
    }
  },
  // ********************************************************************************************************
  // https://dev.datahub.newsandmedia.sk/hpctr/docs
  // ********************************************************************************************************
  async getHpRealtimeCtrData (store, { brand, box }) {
    if (brand && box) {
      store.commit('SET_REALTIME_CTR_DATA_LOADING', true)
      let data = null
      try {
        const result = await dataHubHpctrApi().get(`/realtime?brand=${brand}&box=${box}`)
        data = result.data
      } catch (error) {
        console.error(error)
      }
      if (data) {
        if (store.state.realtimeCtrData.some(item => item.box === box)) {
          store.commit('SET_REALTIME_CTR_DATA', data)
        } else {
          store.commit('ADD_REALTIME_CTR_DATA', data)
        }
      }
      store.commit('SET_REALTIME_CTR_DATA_LOADING', false)
    }
  }
}

const getters = {
  contentBlockItemApi (state, getters, rootState, rootGetters) {
    return rootGetters.vlm ? MANAGED_ITEM_API : MANAGED_ITEMS_API
  },
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  filterQuery (state) {
    return state.filterQuery
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
